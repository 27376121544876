const $ = require("jquery");

export default class Accordion{
    constructor(btn,target,speed = 300){
        this.btn = $(btn);
        this.target = target;
        this.flg = false;
        this.speed = speed;
    }
    init(){
        this.controller();
    }
    modelChange(){
        this.flg = !this.flg;
    }
    controller(){
        this.btn.on('click',eventObject=>{
            if(!this.flg){
                this.slideDownNext(eventObject.currentTarget);
                this.toBtnAcive(eventObject.currentTarget);
                this.modelChange();
            }else{
                this.slideUpNext(eventObject.currentTarget);
                this.toBtnInacive(eventObject.currentTarget);
                this.modelChange();
            }
        })
    }
    toBtnAcive(el){
        $(el).addClass('is_active');
    }
    toBtnInacive(el){
        $(el).removeClass('is_active');
    }
    slideDownNext(el){
        $(el).next(this.target).slideDown(this.speed);
    }
    slideUpNext(el){
        $(el).next(this.target).slideUp(this.speed);
    }
}
