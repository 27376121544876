import $ from 'jquery';
import isPC from './isPC';
export default class Toolmap {
  constructor() {
    this.btn = $('.p-businessPpbizContentBtns__item');
    this.currentNum = null;
  }
  init() {
    /**
     * PCのイベント
     */
    this.btn.on('mouseenter', eventObject => {
      if (isPC()) {
        this.selectNumber(this.getNumber(eventObject));
        this.fillSelectItem();
      }
    });
    this.btn.on('mouseleave', () => {
      if (isPC()) {
        this.resetNumber();
        this.resetFillSelectItem();
      }
    });

    /**
     * SPのイベント
     */
    this.btn.on('click', eventObject => {
      if (!isPC) {

      }
    })
  }
  selectNumber(num) {
    this.currentNum = num;
  }
  getNumber(eventObject) {
    const number = $(eventObject.currentTarget).find('.p-businessPpbizContentBtns__nember').text();
    return number;
  }
  fillSelectItem() {
    const item = `.p-businessPpbizMap__icon--${this.currentNum}`;
    $(item).addClass('is_active');
  }
  resetNumber() {
    this.currentNum = null;
  }
  resetFillSelectItem() {
    $('.p-businessPpbizMap__icon').each((index,el) => {
      $(el).removeClass('is_active');
    })
  }
}