const $ = require('jquery');
export default () => {
    let windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    //let headerH = $('.nav').height();
    $('a[href^="#"]').on('click',function() {
        // header size
        let headerH = $('.l-header').height();

        // スクロールの速度
        let speed = 500; // ミリ秒
        // アンカーの値取得
        let href= $(this).attr("href");
        // 移動先を取得
        let target = $(href == "#" || href === "" ? 'html' : href);
        // 移動先を数値で取得
        //let position =  (windowWidth > 768)? target.offset().top - headerH : target.offset().top;
        let position =  target.offset().top - headerH;
        // スムーススクロール
        $('body,html').animate({scrollTop:position}, speed,'swing');
        return false;
    });
}
