const $ = require("jquery");
import * as PIXI from 'pixi.js'

export default () => {
//-----　初期設定 -----//
    //ランダム整数関数
    function getRandomInt(max) {
        return Math.floor(Math.random() * Math.floor(max));
    }
    function cleanNum(num) {
        return Math.round(num * 100)/100;
    }
    const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    //canvasサイズ
    const box = {
        w: $(window).width(),
        h: $(window).height()
    };
    //canvasリサイズ
    window.addEventListener('resize',function(){
        app.renderer.resize(window.innerWidth, window.innerHeight);
        box.w = $(window).width(),
        box.h = $(window).height()
    });
    //パーティクル数
    const partNum = 100;
    //新規アプリ作成
    const app = new PIXI.Application({
    width: box.w, height: box.h, antialias: true, transparent: true, autoResize: true, resolution: 2})
    //#bgAnimの中にcanvas追加
    document.getElementById('bgAnim').appendChild(app.view);
    //三角の格納用
    const container = [];
    //三角の色
    var color = [0xB2ACD6, 0x83B4A2, 0xE2A5AE, 0xC7BB82, 0x98C2D9, 0xd5d2e4, 0xd4e8e1, 0xf5d1d7, 0xdedac7, 0xd0e2ec];
    //三角の頂点
    const tri = [
        {
            x: 15,
            y: 30
        },
        {
            x: 15,
            y: 25
        },
        {
            x: 20,
            y: 30
        }
    ];
    //三角のサイズ
    let scale = [];
    if(windowWidth > 767){
        scale = [1,0.8,0.7];
    }else{
        scale = [0.6,0.5,0.4];
    }
    //時間
    const time = [];
    //X・Y軸用重力
    const gravX= [];
    const gravY = [];
    //XYの方向
    const angleX = [];
    const angleY = [];

    //変形先の座標・スピード　格納
    const pointX = [];
    const pointY = [];

    const speedX = [];
    const speedY = [];

    //進行方向
    let rot = Math.random() * 360;
    let angle = rot * Math.PI / 180;

    //animation 格納
    let firstAnim;
    let particleAnim;
    let sclAnim_up;
    let sclAnim_down;

    //三角  partNumの数作成
    for (let i = 0; i < partNum; i++) {

        //初回パーティクルアニメーションの時間格納先
        time[i] = 0;

        //徐々にスピード下げる値格納先
        gravY[i] = 0;
        gravX[i] = 1;

        //最初のサークル
        const xd = Math.round((i/(partNum-1)) * 2000);
        let cercle = {};
        if(windowWidth > 767){
            cercle = {
                size: 20,
                x: cleanNum(box.w/2-200),
                y: 325,
            };
        }else{
            cercle = {
                size: cleanNum(box.w/28),
                x: 100,
                y: cleanNum(box.w/2.1),
            };
        }
        //サークルの位置格納
        pointX[i] = (Math.cos(xd)*cercle.size) * (Math.cos(xd)*cercle.size)+cercle.x;
        pointY[i] = (Math.cos(xd)*cercle.size) * (Math.sin(xd)*cercle.size)+cercle.y;

        //各三角のスピード格納先
        speedX[i] = 0
        speedY[i] = 0

        //XYの方向
        rot = Math.random() * 360;
        angle = rot * Math.PI / 180;
        if(windowWidth > 767){
            angleX[i] = getRandomInt(5)*0.5;
        }else{
            angleX[i] = getRandomInt(5)*0.3;
        }
        angleY[i] = cleanNum(Math.sin(angle)*0.5);

        //色の数
        const colorNum = i % color.length
        //三角の頂点の数
        const triNum = i % tri.length

        //三角作成、containerに三角格納
        container.push(new PIXI.Graphics().beginFill(color[colorNum]).moveTo(0, 0).lineTo(30,0).lineTo(tri[triNum].x,tri[triNum].y).closePath());

        //各三角初回の位置
        container[i].position.x = Math.random() * box.w; // X軸
        container[i].position.y = Math.random() * box.h;  // Y軸

        //スケールの数
        const scaleNum = i % scale.length;
        //スケールの適応
        container[i].scale.x = container[i].scale.y = scale[scaleNum];
        //三角の角度
        container[i].rotation = 0.1 + ((i % 5) * 0.5);
        //三角を初回透明度
        container[i].alpha = 0;
        //アプリに三角追加
        app.stage.addChild(container[i]);
    }

//----- 弾けた後の通常のパーティクルアニメーション -----//
    function particle() {
        cancelAnimationFrame(firstAnim);
        cancelAnimationFrame(sclAnim_up);
        cancelAnimationFrame(sclAnim_down);

        particleAnim = requestAnimationFrame(particle);
        for (let i = 0; i < partNum; i++) {
            //透明度
            if(container[i].alpha < 1){
                container[i].alpha += 0.01;
            }

            //登場
            if (time[i] < 0.2) {
                time[i] += 0.01;
                //Y下方向
                if(angleY[i]>0){
                    container[i].position.y += (angleY[i]+1); // Y軸
                }else{
                    container[i].position.y += angleY[i]; // Y軸
                }
                //X初回スピード
                if(container[i].position.x < box.w/2){
                    container[i].position.x -= angleX[i];
                }else{
                    container[i].position.x += angleX[i];
                }
            }else{
                //徐々にYのスピード緩める
                gravY[i] += 0.001;
                if(angleY[i]>0){//Y下方向
                    if((angleY[i]-gravY[i]) > 0){
                        container[i].position.y += (angleY[i]-gravY[i]);
                    }else{
                        container[i].position.y -= angleY[i];
                    }
                }else{
                    container[i].position.y += angleY[i];
                }

                //徐々にXのスピード緩める
                gravX[i] -= 0.005;
                if(gravX[i]>0.1){
                    if(container[i].position.x < box.w/2){
                        container[i].position.x -= (angleX[i]*gravX[i]);
                    }else{
                        container[i].position.x += (angleX[i]*gravX[i]);
                    }
                }else{
                    if(container[i].position.x < box.w/2){
                        container[i].position.x -= (angleX[i]*0.1);
                    }else{
                        container[i].position.x += (angleX[i]*0.1);
                    }
                }
                //角度徐々に変える
                if(i%2==0){
                    container[i].rotation += 0.005;
                }else{
                    container[i].rotation -= 0.005;
                }
            }

            //画面外に来たら戻す
            if(container[i].position.y < -50 || container[i].y > box.h +50 || container[i].x > box.w +50 || container[i].x < -50){
                time[i] = 0;
                gravY[i] = 0;
                gravX[i] = 1;
                container[i].alpha = 0;
                //画面中央3分の1のサイズ
                container[i].position.x = box.w/1.5 * Math.random() + box.w/6;  // X軸
                if(i%2==0){
                    container[i].position.y = (box.h/2) + getRandomInt(100); // Y軸
                }else{
                    container[i].position.y = (box.h/2) - getRandomInt(100); // Y軸
                }
                rot = Math.random() * 360;
                angle = rot * Math.PI / 180;
                if(windowWidth > 767){
                    angleX[i] = getRandomInt(5)*0.5;
                }else{
                    angleX[i] = getRandomInt(5)*0.2;
                }
                angleY[i] = cleanNum(Math.sin(angle)*0.5);
            }
        }
    }
    particle()

//----- スクロール時のパーティクルアニメーション  -----//
    //スクロールした時の早さの加算
    let plusX = 5;
    let plusY = 10;
    if(windowWidth > 767) {
        plusX = 1.2;
        plusY = 0.45;
    }else{
        plusX = 0.2;
        plusY = 0.4;
    }
    //スクロール↑↑
    function sclUp() {
        cancelAnimationFrame(firstAnim);
        cancelAnimationFrame(sclAnim_down);
        sclAnim_up = requestAnimationFrame(sclUp);
        app.stage.rotation = 0;
        for (let i = 0; i < partNum; i++) {
            //透明度
            if(container[i].alpha < 1){
                container[i].alpha += 0.05;
            }
            container[i].position.y += angleY[i];

            //徐々にXのスピード緩める
            gravX[i] -= 0.005;
            if(gravX[i]>0.1){
                if(container[i].position.x < box.w/2){
                    container[i].position.x -= (angleX[i]*gravX[i]);
                }else{
                    container[i].position.x += (angleX[i]*gravX[i]);
                }
            }else{
                if(container[i].position.x < box.w/2){
                    container[i].position.x -= (angleX[i]*0.1);
                }else{
                    container[i].position.x += (angleX[i]*0.1);
                }
            }
            //徐々に角度変える
            if(i%2==0){
                container[i].rotation += 0.01;
            }else{
                container[i].rotation -= 0.01;
            }

            //画面外に来たら中心に戻す
            if(container[i].position.y < -50 || container[i].y > box.h +50 || container[i].x > box.w +50 || container[i].x < -50){
                time[i] = 0;
                gravY[i] = 0;
                gravX[i] = 1;
                container[i].alpha = 0;
                //画面中央3分の1のサイズ
                container[i].position.x = box.w/3 * Math.random() + box.w/3;  // X軸
                if(i%2==0){
                    container[i].position.y = (box.h/2) + getRandomInt(100); // Y軸
                }else{
                    container[i].position.y = (box.h/2) - getRandomInt(100); // Y軸
                }
                //方向の角度再格納
                rot = Math.random() * 360;
                angle = rot * Math.PI / 180;
                angleY[i] = (getRandomInt(5)+1)*plusY;
                if(windowWidth > 767){
                    angleX[i] = getRandomInt(5)*0.5;
                }else{
                    angleX[i] = getRandomInt(5)*0.2;
                }
            }
        }
    }
　　//スクロール↓↓
    function sclDown() {
        cancelAnimationFrame(firstAnim);
        cancelAnimationFrame(sclAnim_up);
        sclAnim_down = requestAnimationFrame(sclDown);
        app.stage.rotation = 0;
        for (let i = 0; i < partNum; i++) {
            //透明度
            if(container[i].alpha < 1){
                container[i].alpha += 0.05;
            }
            container[i].position.y += angleY[i];

            //徐々にXのスピード緩める
            gravX[i] -= 0.005;
            if(gravX[i]>0.1){
                if(container[i].position.x < box.w/2){
                    container[i].position.x -= (angleX[i]*gravX[i]);
                }else{
                    container[i].position.x += (angleX[i]*gravX[i]);
                }
            }else{
                if(container[i].position.x < box.w/2){
                    container[i].position.x -= (angleX[i]*0.1);
                }else{
                    container[i].position.x += (angleX[i]*0.1);
                }
            }
            //徐々に角度変える
            if(i%2==0){
                container[i].rotation += 0.01;
            }else{
                container[i].rotation -= 0.01;
            }

            //画面外に来たら中心に戻す
            if(container[i].position.y < -50 || container[i].y > box.h +50 || container[i].x > box.w +50 || container[i].x < -50){
                time[i] = 0;
                gravY[i] = 0;
                gravX[i] = 1;
                container[i].alpha = 0;
                //画面中央3分の1のサイズ
                container[i].position.x = box.w/3 * Math.random() + box.w/3;  // X軸
                if(i%2==0){
                    container[i].position.y = (box.h/2) + getRandomInt(100); // Y軸
                }else{
                    container[i].position.y = (box.h/2) - getRandomInt(100); // Y軸
                }
                //方向の角度再格納
                rot = Math.random() * 360;
                angle = rot * Math.PI / 180;
                angleY[i] = (getRandomInt(5)+1)*-1*plusY;
                if(windowWidth > 767){
                    angleX[i] = getRandomInt(5)*0.5;
                }else{
                    angleX[i] = getRandomInt(5)*0.2;
                }
            }
        }
    }

    //スクロール
    let animationFlag = false;
    let timer = false;
    let start_pos = 0;
    let flag = true;
    $(window).on('scroll', function(){
        var current_pos = $(this).scrollTop();
        if (current_pos > start_pos+10) {
            if(flag=='up'){
                cancelAnimationFrame(particleAnim);
                cancelAnimationFrame(sclAnim_up);
                sclValue_down();
            }
            flag="down";
            if(!animationFlag) {
                animationFlag = true;
                sclValue_down();
            }
            //スクロール終わった後
            clearTimeout(timer);
            timer = setTimeout(function() {
                animationFlag = false;
                cancelAnimationFrame(sclAnim_up);
                cancelAnimationFrame(sclAnim_down);
                for (let i = 0; i < partNum; i++) {
                    gravY[i] = 0;
                }
                particle();
            },200);
        } else if(current_pos < start_pos-10) {
            if(flag=='down'){
                cancelAnimationFrame(particleAnim);
                cancelAnimationFrame(sclAnim_down);
                sclValue_up();
            }
            flag="up";
            if(!animationFlag) {
                animationFlag = true;
                sclValue_up();
            }
            //スクロール終わった後
            clearTimeout(timer);
            timer = setTimeout(function() {
                animationFlag = false;
                cancelAnimationFrame(sclAnim_up);
                cancelAnimationFrame(sclAnim_down);
                for (let i = 0; i < partNum; i++) {
                    gravY[i] = 0;
                }
                particle();
            },200);
        }
        start_pos = current_pos;
    })

    const sclValue_up = function(){
        cancelAnimationFrame(particleAnim);
        cancelAnimationFrame(sclAnim_down);
        for (let i = 0; i < partNum; i++) {
            rot = Math.random() * 360;
            angle = rot * Math.PI / 180;
            angleY[i] =(getRandomInt(5)+1)*plusY;
            if(windowWidth > 767){
                angleX[i] = getRandomInt(5)*plusX;
            }
        }
        sclUp()
    }

    const sclValue_down = function(){
        cancelAnimationFrame(particleAnim);
        cancelAnimationFrame(sclAnim_up);
        for (let i = 0; i < partNum; i++) {
            rot = Math.random() * 360;
            angle = rot * Math.PI / 180;
            if(windowWidth > 767){
                angleX[i] = getRandomInt(5)*plusX;
            }
            angleY[i] =(getRandomInt(5)+1)*-1*plusY;
        }
        sclDown()
    }
};
