import $ from 'jquery';
import {TweenLite} from "gsap";
import ScrollMagic from "scrollmagic";
import "imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap";
import "imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators";
const controller = new ScrollMagic.Controller();
export default () => {
    
    //追従ボタン(カタログ付き)
    if($('.js-footerPpbiz').length>0){
        $('.backTopPpbiz').css('display', 'block');
        $('.backTop').css('display', 'none');
    }

    new ScrollMagic.Scene({
        triggerElement: ".l-footer",
        duration: 0,
        triggerHook: "onEnter",
        reverse: true,
        offset: 0
    })
    .setClassToggle(".backTop,.backTopPpbiz", "is-stop")
    //.addIndicators()
    .addTo(controller);

    const windowWidth =window.innerWidth||document.documentElement.clientWidth||document.body.clientWidth;
    const backTi = TweenLite.to(".backTop,.backTopPpbiz", 1, {
        opacity: 1
    });
    new ScrollMagic.Scene({
        triggerElement: "main",
        duration: 0,
        reverse: true,
        offset: 600
    })
    .setClassToggle(".backTop,.backTopPpbiz", "is-show")
    .setTween(backTi)
    //.addIndicators()
    .addTo(controller);
};