/*import*/
import $ from 'jquery';
const cookie = require('jquery.cookie');
import ChangeImg from './modules/changeImg';
import Smoothscroll from './modules/smoothscroll';
import SpNav from './modules/spNav';
import CanvasTop from './modules/canvasTop';
// import CanvasTopIE from './modules/canvasTopIE';
// import CanvasTop2 from './modules/canvasTop_2';
import Canvas from './modules/canvas';
// import CanvasIE from './modules/canvasIE';
// import Canvas2 from './modules/canvas_2';
import SclAnim from './modules/sclAnim';
import Reload from './modules/reload';
import BackTop from './modules/backTop';
import Accordion from './modules/accordion';
// import BgScroll from './modules/bgScroll';
import ImageMap from './modules/imageMap';
import OnMouse from './modules/onMouse';
import Toolmap from './modules/toolmap';
import Modal from './modules/Modal';
// import IeScroll from './modules/ieScroll';
import BusinessNav from './modules/businessNav';
import Slide from './modules/slide';
import VideoTop from './modules/videoTop';
import TopReturned from './modules/topReturned';
import TopOnMouse from './modules/topOnMouse';


/*create instance*/
/*load event*/
document.addEventListener('DOMContentLoaded', () => {
    ChangeImg();
    Smoothscroll();
    SpNav();
    Reload();
    BackTop();
    const accordion = new Accordion('.c-accList__ttl', '.c-accList__txt');
    accordion.init();
    // const bgScroll = new BgScroll();
    // bgScroll.init();
    const imageMap = new ImageMap();
    imageMap.init();
    const toolmap = new Toolmap();
    toolmap.init();
    const modal = new Modal();
    modal.init();
    const onMouse = new OnMouse();
    onMouse.init();

    var userAgent = window.navigator.userAgent.toLowerCase();

    const target = $('[class^="p-"]').attr('id');
    const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    var message = 'session';

    switch (target) {
        case 'top':
            var name = $.cookie('name');//cookieの取得
            if (name == 'session') { //TOP 他ページから戻ってきたとき
                $('.p-top__ttl').css({
                    'opacity': 1
                })
                Canvas();
                
                // トップページ通常 アニメ―ション無し
                const topReturned = new TopReturned();
                topReturned.init();
                // console.log("TOP通常");

            } else { //TOP 初回読み込み
//                CanvasTop();
                Canvas();
                SclAnim();

                // トップページ初回 アニメ―ション
                const videoTop = new VideoTop();
                videoTop.init();
                // console.log("TOP初回");

            }
            $.removeCookie('name');
            break;
        case 'page':
            if (!name) {
                $.cookie('name', 
                message, {
                    expires: 1,//有効期限（日数）
                    path: '/'//有効なパス（ドメイン全体で利用したい場合は’/’を設定）
                });
            }

            if (document.getElementById('bgAnim') != null) {//キャンパス（背景の紙ふぶき）があるページだけ
                Canvas();
            }
            // console.log("通常ページ");
            break;
        default:
            break;
    }
    
    /* /business/配下(spヘッダー下の追従メニュー)*/
    if($('.js-nav').length>0){
        const businessNav = new BusinessNav();
        businessNav.init();
    }
        
    /* スライド*/
    if($('.p-serviceRetaiSlide').length>0){
        const slide = new Slide();
        slide.init();
    }

    if($('#top').length>0){//topページのみ
        const topOnMouse = new TopOnMouse();
        topOnMouse.init();
    }
})

 