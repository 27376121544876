import $ from 'jquery';
import isPC from './isPC';

// △画像hover 設定　/business/ppbiz.html

export default class OnMouse {
    constructor(){
        this.offBtnFilling = $('.js-mapImg');
        this.offBtnSales = $('.js-mapImg02');
        this.offBtnPackage = $('.js-mapImg03');
        this.offBtnDm = $('.js-mapImg04');
        this.offBtnShop = $('.js-mapImg05');
        this.offBtnOs = $('.js-mapImg06');
    }
    init(){
        if (isPC()) {// PC
            this.hoverEventFilling();
            this.hoverEventSales();
            this.hoverEventPackage();
            this.hoverEventDm();
            this.hoverEventShop();
            this.hoverEventOs();
        }

        else{// SP
        }
    }
    // 充填包装加工
    hoverEventFilling() {
        $('#link1').on('mouseenter', () => {
        this.FillingSetImg();
        });
        $('#link2').on('mouseenter', () => {
        this.FillingSetSecondImg();
        });
        $('#link1,#link2').on('mouseleave', () => {
          this.FillingOffImg();
        });
    }

    // セールスプロモーション
    hoverEventSales() {
        $('#link3').on('mouseenter', () => {
        this.SalesSetImg();
        });
        $('#link4').on('mouseenter', () => {
        this.SalesSetSecondImg();
        });
        $('#link5').on('mouseenter', () => {
            this.SalesSetThirdImg();
            });
        $('#link9').on('mouseenter', () => {
            this.SalesSetFourthImg();
            });
        $('#link3,#link4,#link5,#link9').on('mouseleave', () => {
          this.SalesOffImg();
        });
    }

    // 包発送
    hoverEventPackage() {
        $('#link6').on('mouseenter', () => {
        this.PackageSetImg();
        });
        $('#link7').on('mouseenter', () => {
        this.PackageSetSecondImg();
        });
        $('#link6,#link7').on('mouseleave', () => {
            this.PackageOffImg();
        });
    }

    // DM 発送代行
    hoverEventDm() {
        $('#link8').on('mouseenter', () => {
        this.DmSetImg();
        });
        $('#link8').on('mouseleave', () => {
            this.DmOffImg();
        });
        // $('#link9').on('mouseenter', () => {
        // this.DmSetSecondImg();
        // });
        // $('#link8,#link9').on('mouseleave', () => {
        //     this.DmOffImg();
        // });
    }


    // 商品開発 100円ショップ
    hoverEventShop() {
        $('#link10').on('mouseenter', () => {
        this.ShopSetImg();
        });
        $('#link11').on('mouseenter', () => {
        this.ShopSetSecondImg();
        });
        $('#link10,#link11').on('mouseleave', () => {
            this.ShopOffImg();
        });
    }

    // 海外ネットワーク
    hoverEventOs() {
        $('#link12').on('mouseenter', () => {
        this.OsSetImg();
        });
        $('#link13').on('mouseenter', () => {
        this.OsSetSecondImg();
        });
        $('#link12,#link13').on('mouseleave', () => {
            this.OsOffImg();
        });
    }


    // 充填包装加工 画像パス差し替え
    FillingSetImg(){
        this.offBtnFilling.attr('src', '/assets/img/business/fap_btn_on1.png');
    }
    FillingSetSecondImg(){
        this.offBtnFilling.attr('src', '/assets/img/business/fap_btn_on2.png');
    }
    FillingOffImg(){
        this.offBtnFilling.attr('src', '/assets/img/business/fap_btn_off.png');
    }

    // セールスプロモーション　画像パス差し替え
    SalesSetImg(){
        this.offBtnSales.attr('src', '/assets/img/business/btn-on1.png');
    }
    SalesSetSecondImg(){
        this.offBtnSales.attr('src', '/assets/img/business/btn-on2.png');
    }
    SalesSetThirdImg(){
        this.offBtnSales.attr('src', '/assets/img/business/btn-on3.png');
    }
    SalesSetFourthImg(){
        this.offBtnSales.attr('src', '/assets/img/business/btn-on4.png');
    }
    SalesOffImg(){
        this.offBtnSales.attr('src', '/assets/img/business/btn_off.png');
    }

    // 梱包発送 画像パス差し替え
    PackageSetImg(){
        this.offBtnPackage.attr('src', '/assets/img/business/packing_btn_on1.png');
    }
    PackageSetSecondImg(){
        this.offBtnPackage.attr('src', '/assets/img/business/packing_btn_on2.png');
    }
    PackageOffImg(){
        this.offBtnPackage.attr('src', '/assets/img/business/packing_btn_off.png');
    }

    // DM 発送代行 画像パス差し替え
    DmSetImg(){
        this.offBtnDm.attr('src', '/assets/img/business/dm_btn_on1.png');
    }
    DmSetSecondImg(){
        this.offBtnDm.attr('src', '/assets/img/business/dm_btn_on2.png');
    }
    DmOffImg(){
        this.offBtnDm.attr('src', '/assets/img/business/dm_btn_off.png');
    }


    // 商品開発 100円 ショップ向け 画像パス差し替え
    ShopSetImg(){
        this.offBtnShop.attr('src', '/assets/img/business/shop_btn_on1.png');
    }
    ShopSetSecondImg(){
        this.offBtnShop.attr('src', '/assets/img/business/shop_btn_on2.png');
    }
    ShopOffImg(){
        this.offBtnShop.attr('src', '/assets/img/business/shop_btn_off.png');
    }

    // 海外ネットワーク
    OsSetImg(){
        this.offBtnOs.attr('src', '/assets/img/business/overseas_btn_on1.png');
    }
    OsSetSecondImg(){
        this.offBtnOs.attr('src', '/assets/img/business/overseas_btn_on2.png');
    }
    OsOffImg(){
        this.offBtnOs.attr('src', '/assets/img/business/overseas_btn_off.png');
    }

}

