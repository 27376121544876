import $ from 'jquery';
export default class BusinessNav {
    constructor() {
        
    }
    init() {
        const targetName = $('.js-nav').attr('data-nav');//data-navの値を取得
        this.activeTarget = $(`.js-${targetName}`);//activeページのclass名をセット
        this.activeTarget.addClass('is-hide');

        
    }
  };
  