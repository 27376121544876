import Swiper from "swiper";

export default class Slide{
    constructor(){

    }
    init(){
        const mySwiper = new Swiper ('.swiper-container', {
            loop: true,
            speed: 400,
            slidesPerView: 4,
            spaceBetween: 10,
            centeredSlides: true,
            breakpoints: {
              // スライドの表示枚数：768px以上の場合
              768: {
                spaceBetween: 23,
                centeredSlides: false,
              }
            },
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
            // centeredSlides: true,
            // preventClicks: false,//スワイプ中の不要なクリック無効
            // preventClicksPropagation: false,//スワイプ中の不要なクリックイベント無効
            // allowTouchMove: false,//フリック操作を無効
          })
    }
  
}
