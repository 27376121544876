import $ from 'jquery';
export default class Modal {
    constructor() {
      this.dataName = null;
      this.modal = null;
      this.isOpen = false;
    }
    init() {
      this.clickEvent();
    }
    clickEvent() {
      $('.js-modalBtn').on('click', eventObject => {
        this.setDataName(eventObject.currentTarget);
        this.setModal();
        this.showModal();
      });
      $('.m-modal__bg,.m-modal__icon').on('click', () => {
        this.hideModal();
      })
    }
    /**
     * data-modalの値を取得
     */
    setDataName(el) {
      const targetName = $(el).attr('data-modal');
      this.dataName = targetName;
    }
    setModal() {
      this.modal = $(`#${this.dataName}`);
    }
    /**
     * モーダルを開く
     */
    showModal() {
      this.modal.addClass('is_open');
      this.isOpen = true;
    }
    /**
     * モーダルを閉じる
     */
    hideModal() {
      this.modal.removeClass('is_open');
      this.isOpen = false;
    }
  };
  