// const $ = require("jquery");
import isPC from './isPC';

export default class TopReturned{//トップページ初回以外（kvアニメーション無し）
    constructor(){
    }
    init(){
        //読み込み時に動画を非表示にする
        var videoElem = document.querySelector('.js-topVideo');
        videoElem.classList.remove('is-show');

        //読み込み時にkvタイトルを表示にする
        var titleElem = document.querySelector('.js-topVideoTtl');
        titleElem.classList.add('is-show');

        //読み込み時にkv画像を表示にする
        var itemElem = document.querySelector('.js-topVideoItem');
        itemElem.classList.add('is-show');
    }
}
