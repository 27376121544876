const $ = require("jquery");
import {
    TweenLite
} from "gsap";
import {
    TimelineLite
} from "gsap";
import ScrollMagic from "scrollmagic";
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';

export default () => {
    const controller = new ScrollMagic.Controller;

    //business model
    TweenLite.to('.p-top__ttl', 2.5, {
        opacity: 1,
        delay: 0.8
    })


    // 製品・サービス
    const topPro = new TimelineLite();
    topPro.from('.p-topPro', 2.5, {
        opacity: 0,
        y: 40
    })

    //OUR BUSINESS タイトル
    const topListTtl = new TimelineLite();
    topListTtl.from('.p-topBox', 0.8, {
        opacity: 0,
        y: 40
    })
    // new ScrollMagic.Scene({
    //         triggerElement: ".p-topBox__ttl",
    //         duration: 0,
    //         reverse: false,
    //         offset: -500
    //     })
    //     .setTween(topListTtl)
    //     .addTo(controller);



    //OUR BUSINESS
    const topList = new TimelineLite();
    topList.from('.p-topBox__list', 0.8, {
        opacity: 0,
        y: 40
    })
    .staggerFrom('.p-topBox__item', 0.8, {
        opacity: 0,
        y: 40
    }, 0.2, '-=0.8')
    new ScrollMagic.Scene({
            triggerElement: ".p-topBox__item",
            duration: 0,
            reverse: false,
            offset: -500
        })
        .setTween(topList)
        .addTo(controller);


    //online shop
    const online = TweenLite.from('.linkOnline', 0.8, {
        opacity: 0,
        y: 40
    })
    new ScrollMagic.Scene({
            triggerElement: ".linkOnline",
            duration: 0,
            reverse: false,
            offset: -500
        })
        .setTween(online)
        //.addIndicators()
        .addTo(controller);

    //ir
    const onlineTi = new TimelineLite();
    onlineTi.from('.p-top__ir .c-ttl', 0.8, {
        opacity: 0,
        y: 40
    }).staggerFrom('.c-ir__list .listItem', 0.8, {
        opacity: 0,
        y: 40
    }, 0.2, '-=0.8')
    new ScrollMagic.Scene({
            triggerElement: ".c-ir__list .listItem",
            duration: 0,
            reverse: false,
            offset: -500
        })
        .setTween(onlineTi)
        //.addIndicators()
        .addTo(controller);

    //news
    const news = TweenLite.from('.p-top__news', 0.8, {
        opacity: 0,
        y: 40
    })
    new ScrollMagic.Scene({
            triggerElement: ".p-top__news",
            duration: 0,
            reverse: false,
            offset: -500
        })
        .setTween(news)
        //.addIndicators()
        .addTo(controller);

    //p-topPromo
    const promo = TweenLite.from('.p-topPromo', 0.8, {
        opacity: 0,
        y: 40
    })
    new ScrollMagic.Scene({
        triggerElement: ".p-topPromo",
        duration: 0,
        reverse: false,
        offset: 0
    })
    .setTween(promo)
    //.addIndicators()
    .addTo(controller);

    
    //contact
    const contact = TweenLite.from('.p-topContact', 0.8, {
        opacity: 0,
        y: 40
    })
    new ScrollMagic.Scene({
        triggerElement: ".p-topContact",
        duration: 0,
        reverse: false,
        offset: -500
    })
    .setTween(contact)
    //.addIndicators()
    .addTo(controller);



    // top kv(2021.株主総会リンク)
    // const topKv = new TimelineLite();
    // topKv.from('.p-topKv', 0.8, {
    //     opacity: 0,
    //     y: 40
    // })


    //bnr
    // const bnr = TweenLite.from('.p-topBnr', 0.8, {
    //     opacity: 0,
    //     y: 40
    // })
    // new ScrollMagic.Scene({
    //         triggerElement: ".p-topBnr",
    //         duration: 0,
    //         reverse: false,
    //         offset: -500
    //     })
    //     .setTween(bnr)
    //     //.addIndicators()
    //     .addTo(controller);
};