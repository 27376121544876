// const $ = require("jquery");
import isPC from './isPC';

export default class VideoTop{//初回読み込みトップページ
    constructor(){
    }
    init(){
        //読み込み時にcanvas（背景の紙ふぶき）を見えない状態にする
        var canvasElem = document.querySelector('.js-canvasTop');
        canvasElem.classList.add('is-hide');

         //読み込み時に動画を表示
        const topVideo = document.querySelector('.js-topVideo');
        topVideo.classList.add('is-show');

        if (isPC()) {
            // PC
        }
        else{// SP
            const topVideo = document.querySelector('#topVideo');
            topVideo.src = topVideo.src.replace(/(\.)(mp4)/, "_sp$1$2");
        }

        var videoElem = document.getElementById('topVideo');
        var titleElem = document.querySelector('.js-topVideoTtl');
        var itemElem = document.querySelector('.js-topVideoItem');


        videoElem.addEventListener('ended', function() {//ビデオ再生終了時
            topVideo.style.opacity = '0';
            topVideo.style.zIndex = '-99';


            // 1秒後に実行
            setTimeout(() => {
                titleElem.classList.add('is-show');
                itemElem.classList.add('is-show');
                canvasElem.classList.remove('is-hide');
            }, 1000);
        });
    }
}
