const $ = require("jquery");
import {TweenMax} from "gsap";
export default () => {
    $('.l-header__navSpBtn,.l-header__spMenuCover').on('click',function(){
        $('.l-header__navSpBtn').toggleClass('on');
        $('.l-header__navSpMenu,.l-header__spMenuCover').toggleClass('on');
        if($('.l-header__navSpMenu').hasClass('on')){
            TweenMax.staggerFrom('.navSpItem',0.4,{
                opacity: 0,
                y: 20
            },0.09)
        }
    })
};
