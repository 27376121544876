// const $ = require("jquery");
export default class TopOnMouse{
    constructor(){
        this.btn = document.querySelectorAll('.js-hoverBtn');
        this.item = document.querySelectorAll('.js-hoverItem');
        this.targetLink = null;
    }
    init(){
        this.btn.forEach((el) => {
            el.addEventListener('mouseenter', (e) => {
                this.hoverShow(e.currentTarget);
            });
            el.addEventListener('mouseleave', () => {
                this.leave();
            });
        });
    }
    hoverShow(el){
        const targetName = el.getAttribute('data-btn');
        this.targetLink = document.querySelector(`.p-topMovieItem__link${targetName}`);

        this.btn.forEach((btn) => btn.classList.add('is-transparent'));
        this.item.forEach((item) => item.classList.add('is-transparent'));

        el.classList.remove('is-transparent');
        this.targetLink.classList.remove('is-transparent');
    }

    leave(){
        this.btn.forEach((btn) => btn.classList.remove('is-transparent'));
        this.item.forEach((item) => item.classList.remove('is-transparent'));
    }
}
